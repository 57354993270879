@import '~/src/app/utils/variables.module.scss';

.classic-date-picker {
  display: flex;
  justify-content: center;
  .picker-container {
    border: 1px solid $grey2;
    width: 100%;
    max-width: 400px;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 16px;
    .rdp-month {
      width: 100%;
    }
    .header {
      width: 100%;
      .header-row {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        .label {
          font-size: 16px;
          font-weight: 600;
          width: 130px;
          text-align: center;
        }
        .nav-button {
          height: 30px;
          width: 30px;
          background-color: $grey2;
          margin-top: auto;
          z-index: 2;
          .arrow-icon {
            height: 9px;
          }
        }
        .today-button {
          height: 30px;
          margin-left: auto;
          color: $primaryPurple;
        }
      }
      .day-name-row {
        flex-basis: 100%;
        display: flex;
        justify-content: space-around;
        .day-name {
          width: 40px;
          text-align: center;
          font-size: 12px;
          font-weight: 600;
          color: $grey6;
        }
      }
    }
    .rdp {
      .rdp-months {
        width: 100%;
      }
      .rdp-table {
        width: 100%;
        max-width: none;
        .rdp-head {
          display: none;
        }
        .day-container {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 3px 0 5px;
          &.outside .day-button {
            color: $grey6;
          }
          .day-button {
            height: 36px;
            width: 36px;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $black;
            min-width: auto;
            &:disabled {
              color: $grey4;
            }
          }
          &.today {
            .day-button {
              background-color: $grey3;
              .day-number {
                font-weight: 600;
              }
            }
            &.selected .day-button {
              border: 1px solid $grey5;
            }
          }
          &.selected {
            .day-name {
              font-weight: 600;
            }
            .day-button {
              background-color: $primaryPurple;
              .day-number {
                color: $white;
                font-weight: 600;
              }
            }
          }
          &.hasCases,
          &.hasNeedsAttention {
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: calc(50% - 2px);
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: $grey5;
            }
          }
          &.hasNeedsAttention::after {
            background-color: $primaryPurple;
          }
        }
      }
    }
  }
}
