@import '~/src/app/utils/variables.module.scss';

.timezone-select {
  .MuiButton-root.btn.select-field {
    .MuiButton-endIcon {
      margin-left: auto;
      width: 6px;
      .triangle {
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: rgba(0, 0, 0, 0.54) transparent transparent transparent;
        transform: rotate(0deg);
      }
    }
  }
}

.modal-dialog.timezone-modal {
  .input-container {
    position: relative;
    padding: 32px 24px;
    display: flex;
    height: 108px;
    box-sizing: border-box;
    border-bottom: 1px solid $grey2;
    .back-button {
      flex-shrink: 0;
      margin-right: 8px;
      .svg-icon {
        height: 14px;
      }
    }
    .clear-button {
      height: 42px;
      width: 42px;
      background-color: $white;
      position: absolute;
      right: 25px;
      top: 33px;
    }
    input {
      width: 100%;
      height: 44px;
      background-color: $grey2;
      border-radius: 44px;
      border: none;
      padding: 0 16px;
      box-sizing: border-box;
      font-size: 14px;
      color: $black;
      &::placeholder {
        color: $grey5;
      }
      &.focused,
      &:focus-visible {
        outline: 1px solid $primaryPurple;
      }
    }
  }
}
