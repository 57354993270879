@import '~/src/app/utils/variables.module.scss';

.modal-dialog.invite-dialog .MuiPaper-root {
  height: 100%;
}

.page-container {
  overflow: hidden;
  .invite-page {
    display: flex;
    flex-direction: column;
    background-color: $white;
    flex: 1;
    overflow-y: auto;
    padding: 20px 32px 40px;
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 70px;
      .row {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
      }
      .close-button {
        position: absolute;
        left: 24px;
        .svg-icon {
          height: auto;
        }
      }
      .title {
        font-size: 24px;
        font-weight: 600;
        padding: 0 44px;
        text-align: center;
      }
      .subtitle {
        font-size: 16px;
        margin-top: 8px;
        text-align: center;
        padding: 8px 10px 0;
      }
    }
    .inner-container {
      background-color: $pink1;
      padding: 32px;
      border-radius: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
      .avatar-container {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -70%);
        .MuiAvatar-root {
          border: 2px solid $white;
          height: 60px;
          width: 60px;
          box-sizing: border-box;
        }
      }
      .user-name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
      }
      .expand-container {
        overflow: hidden;
        max-height: 50px;
        transition: max-height 0.5s ease;
        &.max {
          max-height: 380px;
        }
      }
      .create-invite-button {
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        width: 100%;
      }
      .invite-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .qr-box {
          height: 206px;
          width: 206px;
          padding: 10px;
          margin-bottom: 8px;
          box-sizing: border-box;
          background-color: $white;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .qr-actions {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .qr-disclaimer {
            font-size: 12px;
            line-height: 14px;
            color: $primaryPurple;
            text-align: center;
            max-width: 200px;
            margin-bottom: 16px;
            height: 28px;
          }
          .row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 32px;
            width: 100%;
          }
          .share-button,
          .copy-button {
            background-color: $pink2;
            color: $primaryPurple;
            font-weight: 600;
            width: calc(50% - 4px);
          }
          .share-button .MuiButton-startIcon {
            height: 16px;
            width: 16px;
          }
          .copy-button .MuiButton-startIcon {
            height: 18px;
            width: 18px;
          }
          .create-another-button {
            width: 100%;
            height: 50px;
            font-size: 16px;
            font-weight: 600;
            .MuiButton-endIcon {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }
    .invite-disclaimer {
      color: $grey6;
      text-align: center;
      line-height: 21px;
    }
  }
}
