@import '~/src/app/utils/variables.module.scss';

.app-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: $black;
  height: 100%;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: $mq-mobile) {
      max-width: $mq-mobile;
      margin: 0 auto;
    }

    .page-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .header-container {
        position: fixed;
        top: 0;
        z-index: 2;
        @media (min-width: $mq-mobile) {
          max-width: $mq-mobile;
        }
      }
    }
  }
}

.autocomplete-container {
  overflow: hidden;

  .MuiAutocomplete-listbox {
    height: 100vh;
    padding-top: 0;

    .MuiListSubheader-root {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      padding: 0px 24px;
      position: static;
      background-color: $yellow4;
      height: 36px;
      line-height: 30px;
      display: flex;
      align-items: flex-end;
    }
  }

  .list-option {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $black;
    border-bottom: 1px solid $grey2;
    padding: 10px 24px;
    font-family: 'Inter', sans-serif;

    &.selected {
      background-color: $grey4;
    }

    .avatar-container {
      margin-right: 10px;
    }

    .remove-icon {
      margin-left: auto;
      height: 10px;
    }

    .user-role {
      color: $grey6;
      font-size: 12px;
      margin-left: 10px;
      line-height: 17px;
    }

    &.add-your-own {
      color: $primaryPurple;
      border-bottom: none;
      .pencil-icon {
        height: 14px;
        margin-left: 8px;
      }
    }
  }
}

.followers-select {
  border-bottom: 1px solid $grey2;
  margin-bottom: 18px;
  padding-bottom: 18px;

  .follower-chips-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
  }

  .MuiButton-root.btn.add-user-button {
    height: 32px;
    padding: 0 14px;

    .MuiButton-startIcon {
      height: 18px;
      width: 18px;
    }
  }

  .followers-group-container {
    .avatar-group-container {
      height: 34px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .MuiButton-root.collapse-button {
      height: 34px;
      line-height: 34px;
      font-size: 12px;
      color: $grey6;
    }
  }
}

.form-dynamic-select {
  margin-bottom: 20px;

  .autocomplete {
    .autocomplete-input {
      background-color: $grey2;
      border: 1px solid $grey3;
      height: 50px;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 0;

      .MuiAutocomplete-inputRoot {
        height: inherit;
        padding: 0 30px 0 10px;
        font-size: 16px;
      }

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }

      .MuiAutocomplete-endAdornment {
        right: 14px;
        top: 50%;
        transform: translateY(-50%);

        .svg-icon {
          height: auto;
        }
      }
    }

    &.Mui-focused {
      .autocomplete-input {
        border-color: $primaryPurple;
      }
    }
  }

  .MuiAutocomplete-popper {
    max-height: 200px;
    overflow-y: auto;
    border-radius: 12px;
    margin-top: 8px !important;
    border: 1px solid $primaryPurple;

    .list-option {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $black;
      border-bottom: 1px solid $grey2;
      padding: 10px 24px;

      &:last-of-type {
        border-bottom: none;
      }
    }

    .MuiAutocomplete-listbox {
      padding: 0;
    }
  }
}

.text-field-container {
  .text-field.MuiTextField-root {
    background-color: $grey1;
    min-height: 50px;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .MuiInputBase-root {
      height: inherit;
      min-height: 50px;
      border-radius: 12px;
      border: 1px solid $grey2;
      padding: 0 10px;

      .MuiInputBase-input {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        padding: 0;

        &::placeholder {
          color: $grey6;
          opacity: 1;
        }
      }

      &.Mui-focused {
        border-color: $primaryPurple;
      }

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }

    &.withHint {
      .MuiInputBase-root {
        .MuiInputBase-input::placeholder {
          color: $grey5;
          font-size: 14px;
        }

        &.MuiInputBase-multiline {
          line-height: 18px;
        }
      }
    }
  }

  .text-field.description {
    .MuiInputBase-root {
      padding: 10px 0 10px 44px;
    }

    .MuiInputAdornment-root {
      position: absolute;
      top: 10px;
      left: 8px;
      max-height: none;
      height: auto;
      background-color: $pink2;
      border-radius: 50%;
      height: 28px;
      width: 28px;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      .svg-icon {
        height: auto;
      }
    }

    &.read-only {
      background-color: $white;
      color: $black;

      .MuiInputBase-root .MuiInputBase-input {
        -webkit-text-fill-color: $black;
      }

      .MuiInputAdornment-root svg path {
        stroke: $black;
      }
    }
  }

  .text-field-read-only {
    font-size: 16px;
    font-weight: 400;
  }
}

.dropdown-select-container {
  .dropdown-select {
    background-color: $grey1;
    min-height: 50px;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    &.placeholder {
      .MuiSelect-select {
        color: $grey6;
      }
    }
    .MuiSelect-select {
      height: inherit;
      min-height: 50px;
      border-radius: 12px;
      border: 1px solid $grey2;
      padding: 0 10px;
      line-height: 50px;
      &:focus {
        background-color: $grey1;
      }
      &[aria-expanded='true'] {
        border-color: $primaryPurple;
      }
    }
  }
}

.date-modal {
  .modal-content.case-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    .modal-header {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 24px;
      padding: 24px 0 12px;
      font-size: 16px;
      font-weight: 600;
      color: $black;
      position: relative;

      .close-button {
        position: absolute;
        left: 4px;
        bottom: 12px;
        padding: 2px;

        svg {
          height: 12px;
        }
      }
    }

    .picker-container {
      border: none;
    }

    &.postpone-picker {
      .modal-header {
        padding-bottom: 0;
        max-width: 80vw;

        .close-button {
          bottom: unset;
        }
      }

      .header-title {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .case-details {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .case-title {
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          margin-top: 12px;
        }

        .attending {
          display: flex;
          align-items: center;
          background-color: $yellow3;
          height: 26px;
          padding: 0 12px 0 2px;
          border-radius: 26px;
          width: min-content;
          margin: 8px 8px 0 0;

          .name {
            display: flex;
            font-weight: 500;
            margin-left: 8px;
            font-size: 12px;
            line-height: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .same-user {
              color: $grey6;
              white-space: pre;
            }
          }
        }
      }
    }
  }
}

.recommendations-panel {
  background-color: $white;
  position: fixed;
  bottom: 24px;
  left: 24px;
  right: 24px;
  z-index: 4;
  border-radius: 20px;
  padding: 16px;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .icon-container {
      height: 48px;
      width: 48px;
      border-radius: 10px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      background-color: $yellow3;
      margin-right: 16px;

      .svg-icon {
        height: 17px;
      }
    }

    .text-container {
      line-height: 21px;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: $black;
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        color: $grey6;

        .strong {
          color: $black;
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .btn {
      width: 47%;
      height: 48px;
      border-radius: 16px;
      font-size: 16px;
      font-weight: 600;

      &.outlined {
        border-width: 1px;
      }
    }
  }
}

.chip-display-only {
  min-height: 34px;
  border-radius: 16px;
  background-color: $pink2;
  margin-bottom: 12px;
  padding: 7px 18px;
  box-sizing: border-box;
  margin-right: 8px;
  vertical-align: middle;
  line-height: 20px;
}

.text-display-only {
  font-size: 16px;
  font-weight: 400;
  color: $black;
  white-space: pre-line;
  margin-bottom: 12px;
}

.uploadcare--dialog .uploadcare--dialog__container {
  .uploadcare--dialog__close {
    background-color: $grey2;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    padding: 0;
    top: 10px;
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: $grey6;
      height: 24px;
    }
  }

  .uploadcare--text {
    color: $primaryPurple;
    font-family: 'Inter', sans-serif;
  }

  .uploadcare--preview__done,
  .uploadcare--preview__back {
    font-family: 'Inter', sans-serif;
    border-radius: 40px;
    height: 40px;
  }

  .uploadcare--preview__done {
    background-color: $primaryPurple;
    border: none;
  }

  .uploadcare--preview__back {
    background-color: $white;
    border: 1px solid $primaryPeach;
    color: $primaryPeach;
  }
}

.image-confirm-dialog {
  .MuiPaper-root {
    padding-bottom: 10px;

    .MuiDialogTitle-root {
      text-align: center;
      font-family: 'Inter', sans-serif;
      color: $primaryPurple;
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
    }

    .text-field-container {
      margin-top: 20px;
    }
  }
}

// Temp login screen styles
.login-wrapper {
  background-color: $white;
  box-sizing: border-box;
  max-height: 100%;

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-form-container {
    margin-bottom: 20px;

    .auth0-lock .auth0-lock-cred-pane .auth0-lock-cred-pane-internal-wrapper {
      height: auto;
    }
  }

  .text-wrapper {
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: auto;

      h3 {
        font-size: 26px;
        text-align: center;
        margin: 20px 0;
      }
    }
  }
}

.svg-icon {
  height: 20px;
}

.case-list-item-menu {
  .menu-button {
    height: 18px;
    width: 18px;
    padding: 0;

    .svg-icon {
      height: 13px;
      width: 13px;
    }
  }
}

.case-list-item-menu-panel {
  .MuiPaper-root {
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    margin-top: 10px;
  }
}

.case-info-chip {
  background-color: $yellow3;
  height: 34px;
  padding: 0 12px;
  border-radius: 34px;
  margin-left: 8px;
  &,
  .case-info-chip-input .MuiInputBase-input {
    font-size: 14px;
    font-weight: 600;
    line-height: 34px;
  }
  .case-info-chip-input {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiInputBase-input {
      font-family: 'inter', sans-serif;
      height: 100%;
      text-align: center;
    }
  }
}

.custom-toast {
  .Toastify__toast {
    margin: 20px;
    background-color: gray;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    min-height: auto;
    text-align: center;

    &--error {
      background-color: #de6750;
    }

    &--success {
      background-color: $success;
    }

    &--warning {
      background-color: $notification;
    }

    &--info {
      background-color: #789de6;
    }
  }
}

.huddle-stats {
  margin-bottom: 20px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 400;
    text-align: center;
    .svg-icon {
      margin-right: 8px;
    }
    .button-container {
      flex-basis: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 0;
      .invite-button {
        background-color: $grey2;
        border-radius: 20px;
        font-size: 14px;
        color: $primaryPurple;
        .button-icon {
          height: 14px;
          .svg-icon {
            height: 100%;
            margin-right: 2px;
          }
        }
      }
    }
  }
}
