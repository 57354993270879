@import '~/src/app/utils/variables.module.scss';

.nursing-feedback-container {
  .nursing-feedback {
    background-color: $grey2;
    margin: 18px 24px 0;
    border-radius: 12px;
    padding: 16px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nursing-title {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      margin-bottom: 12px;
    }

    .nursing-subtitle {
      font-size: 16px;
      color: $grey6;
      margin-bottom: 12px;
    }

    .feedback-comment {
      color: $grey6;
      font-size: 16px;
      margin-top: 12px;
    }

    .edit-button.outlined {
      border-width: 1px;
      background-color: $grey2;
      padding: 0 16px;
      margin-top: 12px;
      color: $grey6;
      height: 32px;
    }
  }

  .rating {
    pointer-events: none;
    .MuiRating-icon {
      width: 50px;
      .svg-icon {
        height: 34px;
      }
    }
  }

  &.compact {
    display: flex;
    justify-content: flex-end;
  }
}

.feedback-modal.modal-dialog.centered {
  .MuiPaper-root {
    width: 90%;
    padding: 32px;
    box-sizing: border-box;
    position: relative;

    .modal-container {
      .modal-content {
        text-align: center;

        .close-button {
          position: absolute;
          right: 10px;
          top: 10px;
          height: 34px;
          width: 34px;
          background-color: $grey2;
          .svg-icon {
            height: auto;
          }
        }

        .modal-header {
          padding: 0 24px;
          margin-bottom: 20px;

          .case-date {
            font-size: 16px;
            color: $grey6;
          }

          .case-title {
            font-size: 18px;
            font-weight: 600;
          }
        }

        .nursing-title {
          color: $black;
          font-size: 24px;
          font-weight: 600;
        }

        .nursing-subtitle {
          font-size: 16px;
          color: $grey6;
          margin-bottom: 24px;
        }

        .rating {
          margin-bottom: 30px;
          .MuiRating-icon {
            width: 50px;
            .svg-icon {
              height: 34px;
            }
          }
        }

        .submit {
          width: 100%;
          height: 50px;
          font-size: 16px;
        }

        .text-field-container {
          .text-field-label {
            font-size: 14px;
            text-align: left;
          }

          .text-field {
            background-color: $grey2;

            .MuiInputBase-root {
              padding: 10px;
              border-color: $grey3;

              &.Mui-focused {
                border-color: $primaryPurple;
              }
            }
          }
        }
      }
    }
  }
}
