@import '~/src/app/utils/variables.module.scss';

.avatar-container {
  .avatar-image {
    height: 100%;
  }

  .avatar-text {
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 300;
    color: $black;
  }

  &.small {
    .avatar-text {
      font-size: 12px;
    }
  }

  &.medium {
    .avatar-text {
      font-size: 14px;
    }
  }

  &.large {
    .avatar-text {
      font-size: 32px;
    }
  }

  &.with-prefix {
    .avatar-text {
      font-size: 16px;
      font-weight: 600;
    }

    &.small {
      .avatar-text {
        font-size: 9px;
      }
    }

    &.medium {
      .avatar-text {
        font-size: 10px;
      }
    }

    &.large {
      .avatar-text {
        font-size: 26px;
      }
    }
  }
}
