@import '~/src/app/utils/variables.module.scss';

.empty-huddle,
.empty-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image,
  svg {
    max-height: 160px;
    margin-bottom: 14px;
    height: auto;
  }

  .content {
    width: 100%;
    max-width: 340px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 300px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: $black;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $grey6;
      margin: 10px 0 20px;
    }

    .chat-button {
      margin-top: 20px;
    }
  }
}

.empty-huddle {
  height: auto;
  padding-bottom: 100px;
}
