@import '~/src/app/utils/variables.module.scss';

.add-field-panel {
  display: flex;
  flex-direction: column;
  padding: 32px 24px 32px 24px;
  .title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    max-width: 260px;
    white-space: pre-line;
    align-self: center;
    margin-bottom: 20px;
  }
  .content {
    display: flex;
    flex-direction: column;
    .field {
      margin-bottom: 20px;
      .label {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
      }
      .field-name-input {
        width: 100%;
        .MuiInputBase-root {
          color: $grey6;
          font-family: 'inter', sans-serif;
        }
      }
      .type-radio-select {
        .MuiRadio-root {
          color: $grey6;
          + .MuiTypography-root {
            color: $grey6;
            font-family: 'inter', sans-serif;
            font-size: 16px;
          }
          &.Mui-checked {
            color: $primaryPurple;
            + .MuiTypography-root {
              color: black;
            }
          }
        }
      }
    }
  }
  .submit-button {
    align-self: flex-end;
  }
}
