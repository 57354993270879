@import '~/src/app/utils/variables.module.scss';

.modal-dialog.my-account-dialog .MuiPaper-root {
  height: 100%;
  max-height: none;

  .modal-container {
    height: 100%;
    display: flex;
  }
}

.my-account-container {
  padding: 20px 16px 40px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 10px 0;

    .close-button {
      position: absolute;
      left: 24px;

      .svg-icon {
        height: auto;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 36px;

    .profile-picture-container {
      position: relative;
      padding: 4px;
      .account-settings-button {
        height: 68px;
        width: 68px;
        border-radius: 50%;
      }
      .edit-icon-button {
        position: absolute;
        top: 3px;
        right: 3px;
        z-index: 3;
        height: 20px;
        width: 20px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $pink1;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: auto;
        padding: 0;
        .edit-icon {
          height: 12px;
        }
      }
    }

    .user-name {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      margin-top: 10px;
    }
  }

  .actions {
    margin-top: 20px;
  }

  .bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .version {
      font-size: 10px;
      font-weight: 600;
      color: $grey5;
      text-align: center;
    }

    .links {
      .link {
        color: $primaryPurple;
        font-size: 12px;
        font-weight: 600;
        padding: 0 10px;

        &:not(:last-of-type) {
          border-right: 1px solid $black;
        }
      }
    }
  }

  .action-button {
    background-color: $grey1;
    border-radius: 12px;
    border: 1px solid $grey2;
    height: 56px;
    width: 100%;
    padding: 0 12px;
    margin-bottom: 8px;

    .button-content {
      display: flex;
      align-items: center;
      text-align: left;
      width: inherit;
      color: $black;
      font-size: 14px;
      font-weight: 600;

      .button-icon {
        background-color: $pink2;
        height: 32px;
        width: 32px;
        border-radius: 32px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        .svg-icon {
          height: 16px;
          width: 16px;
        }
      }
    }

    &.invite-user-button {
      background-color: $pink2;
      padding-right: 20px;
      height: 64px;

      .button-icon {
        background-color: $white;
        height: 40px;
        width: 40px;
        border-radius: 20px;

        .svg-icon {
          height: 22px;
          width: 22px;
        }
      }

      .MuiButton-endIcon {
        height: 6px;
        width: 6px;
      }
    }

    &.new-site {
      .button-icon .svg-icon {
        height: 18px;
        width: 18px;
      }
    }
  }
}
