@import '~/src/app/utils/variables.module.scss';

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-container {
  background-color: $white;
  padding: 35px 0 47px 0;
  width: 100%;

  .header-main {
    padding: 0 24px 0 24px;
    display: flex;
    align-items: center;

    .logo-container {
      display: flex;
      align-items: flex-end;

      .logo {
        height: 24px;
        width: 24px;
        .lottie-container {
          height: 100%;
          width: 100%;
        }
      }

      .beta-label {
        color: $grey5;
        font-size: 11px;
        line-height: 8px;
        font-weight: 600;
        margin-left: 4px;
        animation: fadeInAnimation ease 1s;
      }
    }

    .user-menu {
      display: flex;
      margin-left: auto;

      .menu-button {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background-color: $grey2;

        &:not(:last-of-type) {
          margin-right: 8px;
        }

        &.huddle-rate {
          background-color: $grey2;
          width: auto;
          padding: 0 12px;
          color: $black;
          font-weight: 600;

          .MuiButton-startIcon {
            height: 20px;
            width: 20px;
          }
        }
        &.invite {
          width: auto;
          min-width: 40px;
          .button-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            position: absolute;
            left: 0;
            top: 0;
          }
          .text-expand-container {
            margin-left: -8px;
          }
          .button-text {
            font-size: 16px;
            color: $primaryPurple;
            max-width: 0;
            overflow: hidden;
            text-wrap: nowrap;
            transition: all 0.5s ease;
            margin-left: 32px;
            padding-right: 0px;
            &.isShown {
              max-width: 100px;
              padding-right: 6px;
            }
          }
        }
      }
    }
  }

  &.non-surgeon,
  &.catch-up {
    border-bottom: 1px solid $grey2;
    padding-bottom: 17px;
    border-radius: 0 0 24px 24px;
  }
}
