@import '~/src/app/utils/variables.module.scss';

.huddle-dialog {
  transition: backdrop-filter 0.3s;
  backdrop-filter: blur(8px) opacity(0);
  &.open {
    backdrop-filter: blur(8px) opacity(1);
  }
  .MuiDialog-container {
    align-items: end;
    .MuiPaper-root {
      background-color: $white;
      margin: 0;
      width: 100vw;
      border-radius: 16px 16px 0 0;
    }
  }
}
