@import '~/src/app/utils/variables.module.scss';

.page-container {
  overflow: hidden;
  .cases-page {
    display: flex;
    flex-direction: column;
    background-color: $white;
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
