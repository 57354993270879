@import '~/src/app/utils/variables.module.scss';

.modal-container {
  .comments {
    padding: 0;
    padding: 110px 24px;
    display: flex;
    flex-direction: column;
    .comments-container {
      padding-top: 20px;
      &.hasNotification {
        padding-bottom: 20px;
      }
      .comment {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        .comment-user {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          .avatar-container {
            margin-right: 8px;
          }
          .user-name {
            font-size: 12px;
            font-weight: 600;
          }
        }
        .comment-bubble {
          background-color: $white;
          border: 1px solid $grey3;
          border-radius: 0 12px 12px 12px;
          padding: 12px;
          margin-bottom: 36px;
          max-width: 60%;
          display: flex;
          align-items: flex-end;
          position: relative;
          .comment-text {
            white-space: pre-line;
            line-height: 20px;
          }
          .comment-time {
            color: $grey6;
            font-size: 12px;
            padding-left: 6px;
            line-height: 20px;
          }
        }
        &.self {
          align-items: flex-end;
          .comment-bubble {
            background-color: $pink2;
            border-color: $pink1;
            border-radius: 12px 12px 0 12px;
          }
        }
        .unread-separator {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 10px;
          .separator-text {
            font-size: 12px;
            font-weight: 600;
            flex-shrink: 0;
            margin: 0 10px;
          }
          &::before,
          &::after {
            content: '';
            display: block;
            height: 1px;
            background-color: $grey3;
            width: 100%;
          }
        }
        .like-button {
          background-color: $pink2;
          border-radius: 24px;
          height: 24px;
          padding: 0;
          min-width: 24px;
          position: absolute;
          left: 0;
          bottom: -32px;
          display: flex;
          align-items: center;
          justify-content: center;
          .button-content {
            display: flex;
            align-items: center;
            .svg-icon {
              height: 16px;
            }
            .count {
              color: $black;
              font-size: 12px;
              line-height: 12px;
              font-weight: 600;
              margin-left: 6px;
            }
          }
          &.liked {
            background-color: $primaryPurple;
            .button-content {
              .count {
                color: $white;
              }
            }
          }
          &.othersLiked {
            padding: 0 8px;
          }
        }
        .read-receipts {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 10px;
          margin-left: auto;
          width: calc(100% - 38px);
          display: flex;
          flex-wrap: wrap;
          .receipt {
            margin: 0 2px 4px;
            .avatar-container .MuiAvatar-root {
              width: 18px;
              height: 18px;
              .avatar-text {
                font-size: 10px;
              }
            }
          }
        }
      }
      .comment-form {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-left: -24px;
        .notifications {
          background-color: $grey2;
          display: flex;
          padding: 12px 24px;
          .svg-icon {
            height: 16px;
            margin-right: 8px;
          }
          .notification-text {
            font-size: 12px;
            line-height: 16px;
            color: $grey6;
          }
        }
        .input-container {
          display: flex;
          border-top: 1px solid $grey3;
          padding: 24px;
          background-color: $white;
          box-sizing: border-box;
          .comment-title {
            background-color: $grey2;
            border-radius: 44px;
            padding: 0 16px;
            width: 100%;
            margin-right: 8px;
            border: solid 1px $grey2;
            font-size: 16px;
            display: flex;
            justify-content: center;
            .MuiInputBase-root {
              textarea {
                &::placeholder {
                  line-height: 26px;
                }
              }
            }
            &:focus-within {
              border: solid 1px $primaryPurple;
            }
          }
          .submit-message-button {
            height: 44px;
            width: 44px;
            padding: 0;
            min-width: auto;
            flex-shrink: 0;
            .svg-icon {
              height: auto;
            }
          }
        }
      }
    }
  }
}
