@import '~/src/app/utils/variables.module.scss';

.page-container {
  &.onboarding,
  &.whats-new {
    overflow: hidden;
    background-color: $primaryYellow;

    .onboarding-page {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;
      padding-top: 32px;
      position: relative;

      .onboarding-header {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 32px;

        .flex {
          flex: 1;
          display: flex;
        }

        .logo {
          height: 33px;
        }

        .progress {
          display: flex;
          flex-shrink: 0;
          justify-content: center;

          .bar {
            height: 4px;
            width: 16px;
            border-radius: 6px;
            background-color: $yellow2;
            transition: all 0.3s ease-in-out;

            &:not(:last-child) {
              margin-right: 4px;
            }

            &.current {
              width: 30px;
              background-color: $primaryPurple;
            }
          }
        }

        .skip-button {
          color: $primaryPurple;
          font-weight: 600;
          margin-left: auto;
        }
      }

      .screen-conainer {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 122px;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;
        padding: 0 24px 50px;

        &.current {
          opacity: 1;
          pointer-events: all;
        }

        &.profile {
          top: 60px;
        }

        .image {
          flex: 1;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 200px;
          min-height: 80px;
          margin-bottom: 70px;
          svg {
            position: absolute;
            height: 100%;
          }
        }

        .screen-content {
          color: $black;
          padding: 0 10px;
          margin-bottom: 20px;

          .title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 16px;
          }

          .text {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            line-height: 30px;

            .bullet {
              width: 100%;
              position: relative;
              padding-left: 16px;
              margin-bottom: 10px;
              font-size: 18px;
              line-height: 24px;

              &::before {
                content: '';
                display: inline-block;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $primaryPurple;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }
            &.contact {
              margin-top: 20px;
              button {
                border: none;
                background-color: transparent;
                width: fit-content;
                padding: 0;
                font-family: 'Inter', sans-serif;
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
              }
            }
          }
        }

        .actions {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;

          .btn {
            .svg-icon {
              height: auto;
            }

            &.next {
              background-color: $primaryPurple;
              margin-left: auto;
            }

            &.back {
              background-color: $yellow2;
            }
          }
        }

        .profile-screen {
          flex: 1;
          display: flex;
          flex-direction: column;

          .profile-picture {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .profile-container {
              position: relative;
              margin-top: 20px;
              .edit-icon-button {
                position: absolute;
                top: 2px;
                right: 2px;
                z-index: 3;
                height: 24px;
                width: 24px;
                background-color: $white;
                border-radius: 50%;
                border: 1px solid $pink1;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: auto;
                padding: 0;
                .edit-icon {
                  height: 14px;
                }
              }
            }
            .upload-photo-button {
              position: relative;
              height: 106px;
              width: 106px;
              background-color: $pink2;
              border-radius: 50%;
              margin-bottom: 20px;
              .penguin-placeholder {
                height: 50px;
              }
              &::before {
                content: '';
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='53' ry='53' stroke='plum' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
                border-radius: 53px;
              }
            }
            .upload-photo-button-secondary {
              padding: 0 30px;
              border-width: 1px;
              font-weight: 600;
            }

            .display-name-wrapper {
              width: 100%;
              margin-bottom: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .display-name-container {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding-right: 20px;
                border-bottom: 2px solid transparent;
                &.isEditing {
                  border-color: $primaryPurple;
                }
                .display-name-input {
                  background-color: transparent;
                  border: none;
                  font-family: 'Inter', sans-serif;
                  font-size: 16px;
                  font-weight: 600;
                  text-align: center;
                  padding: 0;
                  min-width: 20px;
                  outline: none;
                }
                .clear-button,
                .edit-icon {
                  position: absolute;
                  right: 0;
                }
                .clear-button {
                  height: 16px;
                  width: 16px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-width: 0;
                  padding: 0;
                  border-radius: 0;
                  .clear-icon {
                    height: 8px;
                  }
                }
                .edit-icon {
                  height: 16px;
                  margin-left: 3px;
                }
              }
              .error-message {
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                color: $warning;
                line-height: 14px;
                height: 26px;
                margin-bottom: 6px;
              }
            }
          }
          .profile-fields {
            .text-field-container,
            .dropdown-select-container {
              .dropdown-select .MuiSelect-select,
              .text-field.MuiTextField-root .MuiInputBase-root {
                border-color: $grey3;
                &[aria-expanded='true'] {
                  border-color: $primaryPurple;
                }
                &.Mui-focused {
                  border-color: $primaryPurple;
                }
              }
              .profile-field-label {
                flex-basis: 100%;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 12px;
                padding-left: 6px;
              }
            }
            .text-field-container {
              position: relative;
              padding-top: 26px;
              .error-message {
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                color: $warning;
                line-height: 14px;
                height: 26px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
          .header {
            text-align: center;

            .text {
              font-size: 18px;
              line-height: normal;
              color: $grey6;
            }
          }

          .actions {
            flex-direction: column;

            .save-profile-button {
              height: 50px;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            .skip-button {
              color: $primaryPurple;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  &.whats-new .screen-content .text {
    white-space: pre-line;
  }
}
