@import '~/src/app/utils/variables.module.scss';

.follower-chip {
  background-color: $white;
  margin: 0 8px 10px 0;
  height: 34px;
  line-height: 34px;
  font-weight: 500;
  padding: 0 8px 0 3px;
  border: 1px solid $grey3;
  color: $black;
  border-radius: 17px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: auto;
  position: relative;

  .avatar-container {
    margin-right: 8px;
  }

  &.seen {
    padding-right: 28px;

    .seen-icon-container {
      position: absolute;
      height: 18px;
      width: 18px;
      right: 6px;
      .seen-icon {
        right: 0;
      }
    }
    .seen-icon {
      position: absolute;
      height: 18px;
      right: 6px;
    }
  }
}
