@import '~/src/app/utils/variables.module.scss';

.offline-page {
  background-color: $white;
  padding: 0 32px 60px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9999; // high zindex to overcome modals zindex
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    .image {
      max-height: 200px;
      margin-bottom: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: 600;
      color: $black;
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $grey6;
    }
  }
}
