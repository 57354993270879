@import '~/src/app/utils/variables.module.scss';

.add-template-panel {
  padding-left: 16px;
  .suggestion-button-container {
    margin: 5px 0;
    max-width: 90%;
    .suggestion {
      text-align: left;
      padding: 0 18px;
      box-sizing: border-box;
      height: auto;
      background-color: $pink2;
      border: none;
      p {
        width: 100%;
        margin: 0.5em 0;
        color: $black;
      }
      .MuiButton-endIcon {
        height: 24px;
        width: 24px;
        display: flex;
        flex-shrink: 0;
        border-radius: 50%;
        .svg-icon {
          height: 12px;
        }
      }
    }
  }
}

.add-template-panel-content {
  padding: 20px 0 40px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 0 0 20px 0;
    border-bottom: 1px solid $grey3;
    margin-bottom: 20px;

    .close-button {
      position: absolute;
      left: 24px;

      .svg-icon {
        height: auto;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .content-wrapper {
    padding: 0 24px;
    .title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .edit-icon {
        height: 26px;
        width: 26px;
        background-color: $pink2;
        border-radius: 50%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .svg-icon {
          height: 14px;
        }
      }
    }

    .template-name-container {
      margin-bottom: 10px;
      .text-field.MuiTextField-root {
        height: 44px;
        min-height: 44px;
        .MuiInputBase-root {
          height: inherit;
          min-height: inherit;
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
}
