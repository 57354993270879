@import '~/src/app/utils/variables.module.scss';

.chip {
  background-color: $primaryPurple;
  min-height: 24px;
  max-height: 34px;
  min-width: 66px;
  padding: 0 9px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border: none;
  .chip-content {
    white-space: pre-wrap;
    text-align: center;
    line-height: 14px;
    padding: 2px 0;
  }
  &.success {
    background-color: $success;
  }
  &.notification {
    background-color: $notification;
    color: $black;
  }
}
