@import '~/src/app/utils/variables.module.scss';

.huddle-images {
  padding: 0 24px;
  .form-group-label {
    flex-basis: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .gallery-container {
    position: relative;
    .add-button {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 68px;
      width: 68px;
      border-radius: 12px;
      background-color: $pink2;
      .MuiButton-startIcon {
        margin: 0;
        height: 50px;
        width: 50px;
      }
      &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='plum' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
        border-radius: 8px;
      }
    }
    .images-gallery {
      .image-grid {
        display: flex;
        flex-wrap: wrap;
        .thumbnail {
          height: 68px;
          width: 68px;
          position: relative;
          margin-right: 10px;
          margin: 0 10px 10px 0;
          .image {
            height: 68px;
            width: 68px;
            border-radius: 10px;
            object-fit: cover;
          }
          .delete-button {
            position: absolute;
            right: -6px;
            top: -6px;
            height: 30px;
            width: 30px;
            .MuiButton-endIcon {
              height: 18px;
              width: 18px;
            }
          }
        }
        &.edit-mode {
          &::before {
            content: '';
            height: 68px;
            width: 68px;
            margin-right: 10px;
          }
        }
      }
    }
    .uploadcare--widget {
      display: none;
    }
    .uploadcare--widget__button {
      display: none;
    }
  }
  .swiper-container {
    background-color: rgba($black, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    padding-bottom: 20px;
    padding: 0 10px 20px;
    box-sizing: border-box;
    .swiper-button-next,
    .swiper-button-prev {
      &::after {
        color: $primaryYellow;
      }
    }
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9;
    }
    .slides {
      height: 80%;
      .swiper-wrapper {
        .swiper-slide {
          img {
            width: 90%;
          }
        }
      }
    }
    .tumbnails {
      height: 20%;
      &::after,
      &::before {
        content: '';
        height: 100%;
        width: 40px;
        position: absolute;
        top: 0;
        z-index: 2;
      }
      &::after {
        background: linear-gradient(to right, rgba($black, 1), transparent);
        left: 0;
      }
      &::before {
        background: linear-gradient(to left, rgba($black, 1), transparent);
        right: 0;
      }
      .swiper-wrapper {
        .swiper-slide {
          img {
            border: 2px solid $primaryYellow;
            height: 70%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
