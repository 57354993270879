@import '~/src/app/utils/variables.module.scss';

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 302px 0 40px 0;
  height: 100%;
  overflow: hidden;
  &.no-toggles {
    margin-top: 250px;
  }
  &.no-pickers {
    margin-top: 165px;
  }
  .empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 100px);
    .image-container {
      max-width: 80%;
      height: 180px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      svg {
        height: 100%;
      }
    }
    .text {
      font-size: 16px;
      line-height: 22px;
      color: $grey6;
      margin-bottom: 12px;
      max-width: 90%;
      text-align: center;
      white-space: pre-wrap;
    }
    .nav-button {
      background-color: $pink2;
      color: $primaryPurple;
    }
  }
}
