@import '~/src/app/utils/variables.module.scss';

.MuiTooltip-popper {
  z-index: 1 !important;
  .MuiTooltip-tooltip {
    padding: 6px;
    max-width: 260px;
    border-radius: 8px;
    z-index: 2;
    margin-left: 20px;
    margin-right: 20px;
    width: 90vw;
    background-color: $pink1;
    box-shadow: 0px 5px 20px -2px rgba(0, 0, 0, 0.25);
    .tooltip-contnet {
      position: relative;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      line-height: 18px;
      color: $black;
      padding: 10px 20px;
      vertical-align: sub;
      white-space: pre-line;
      .svg-icon {
        vertical-align: bottom;
        height: 19px;
        width: 19px;
      }
      .close-tooltip-button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        min-width: 20px;
        height: 20px;
        .MuiButton-startIcon {
          margin-right: 0;
          margin-left: 0;
          height: 12px;
          width: 12px;
        }
      }
    }
    .MuiTooltip-arrow {
      color: $pink1;
    }
  }
}
