@import '~/src/app/utils/variables.module.scss';

.avatar-group-container {
  display: flex;
  align-items: center;
  .avatar-group {
    padding-left: 9px;
    .MuiAvatarGroup-avatar {
      font-size: 26px;
    }
    .avatar-container {
      margin-left: -7px;
    }
    &.small {
      .MuiAvatarGroup-avatar {
        font-size: 12px;
      }
    }
    &.medium {
      .MuiAvatarGroup-avatar {
        font-size: 16px;
      }
    }
    &.double-digit {
      .MuiAvatarGroup-avatar {
        font-size: 20px;
      }
      &.small .MuiAvatarGroup-avatar {
        font-size: 8px;
      }
      &.medium .MuiAvatarGroup-avatar {
        font-size: 11px;
      }
    }
  }
}
