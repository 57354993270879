@import '~/src/app/utils/variables.module.scss';

.error-page {
  background-color: $white;
  padding: 0 32px 60px;
  box-sizing: border-box;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    .image {
      max-height: 200px;
      margin-bottom: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: 600;
      color: $black;
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $grey6;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    .btn {
      height: 50px;
      font-size: 16px;
      &.report-issue {
        margin-top: 16px;
        background-color: $pink2;
        color: $primaryPurple;
      }
    }
  }
}
