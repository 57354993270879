@import '~/src/app/utils/variables.module.scss';

.MuiButton-root.btn {
  height: 40px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  font-family: 'inter', sans-serif;

  .MuiTouchRipple-child {
    background-color: $primaryPurple;
  }

  &.primary {
    background-color: $primaryPurple;
    color: $white;
    padding: 0 20px;

    &.disabled {
      background-color: $pink1;
    }
  }

  &.secondary {
    background-color: $grey3;
    color: $black;
  }

  &.outlined {
    background-color: $white;
    border: 2px solid $primaryPurple;
    color: $primaryPurple;

    &.full {
      background-color: $primaryPurple;
      color: $white;
    }
  }

  &.circle-button {
    height: 44px;
    width: 44px;
    min-width: auto;
    background-color: $grey2;

    .MuiButton-startIcon,
    .MuiButton-endIcon {
      margin: 0;
    }
  }

  &.chip-button {
    background-color: $grey2;
    height: 34px;
    min-height: 34px;
    padding-left: 6px;
    border: 1px solid $grey3;
    color: $grey6;
    font-weight: 400;
    line-height: 14px;
    margin-right: 8px;

    .MuiButton-startIcon {
      background-color: $white;
      border-radius: 50%;
      height: 28px;
      width: 28px;
      flex-shrink: 0;

      svg {
        height: 15px;
      }
    }

    .MuiButton-endIcon {
      height: 18px;
      width: 18px;
      margin-right: 0px;
      margin-left: 4px;
    }

    &.toggle-chip {
      background-color: $grey2;
      color: $grey6;
      margin-bottom: 12px;
      padding: 0 14px;
      border-color: $grey3;

      &.selected {
        background-color: $pink2;
        border-color: $primaryPurple;
        color: $black;
      }

      &.other-chip {
        height: auto;
        line-height: 20px;
      }
    }

    .surgeon-label {
      max-height: 34px;
      max-width: 90px;
      text-overflow: ellipsis;
      line-height: 17px;
      overflow: hidden;
    }

    &.has-value {
      background-color: $yellow3;
      border-color: $yellow3;
      color: $black;
      font-weight: 600;
    }
  }

  &.select-field {
    background-color: $grey1;
    min-height: 52px;
    height: auto;
    width: 100%;
    padding: 0 12px;
    border: 1px solid $grey2;
    color: $black;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    border-radius: 12px;
    justify-content: flex-start;

    .select-field-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .placeholder {
        color: $grey6;
      }

      .tag {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        color: $notification;
        background-color: $yellow4;
        border-radius: 12px;
        padding: 0 8px;
        height: 24px;

        &::before {
          content: '';
          background: $notification;
          height: 4px;
          width: 4px;
          border-radius: 2px;
          margin-right: 6px;
        }
      }
    }

    .MuiButton-startIcon {
      background-color: $pink2;
      border-radius: 50%;
      height: 28px;
      width: 28px;
      flex-shrink: 0;
      margin-right: 10px;

      svg {
        height: 15px;
      }
    }

    &.read-only {
      background-color: $pink2;
      pointer-events: none;

      .MuiButton-startIcon {
        background-color: $grey1;
      }
    }
  }

  &.fab {
    scale: 1;
    opacity: 1;
    transition: all 0.16s ease-in;
    transform-origin: 0 0;
    height: 60px;
    min-width: 60px;
    background-color: $primaryPurple;
    border-radius: 20px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -32px);
    box-shadow: 0px 6px 20px 0px #00000026;
    z-index: 3;
    color: $white;

    &.right-align {
      left: unset;
      right: 0;
      transform: translate(-24px, -32px);
    }

    &.submit {
      .button-content {
        padding: 0 6px;
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        white-space: pre-line;

        svg {
          margin-right: 10px;
        }
      }

      svg {
        height: 18px;
        width: 18px;
      }
    }

    .MuiButton-startIcon,
    .MuiButton-endIcon,
    svg {
      height: 17px;
      width: 17px;
      margin: 0;
    }

    &.disabled {
      background-color: $pink1;
      pointer-events: none;
    }

    &.isTransitioning {
      scale: 0;
      opacity: 0;
    }

    &.circle {
      border-radius: 50%;
    }

    &.new-case-fab,
    &.toggle-edit-fab {
      .button-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .text-expand-container {
        margin-left: -8px;
      }
      .button-text {
        font-size: 18px;
        max-width: 0;
        overflow: hidden;
        text-wrap: nowrap;
        transition: all 0.5s ease;
        margin-left: 32px;
        padding-right: 0px;
        &.isFull {
          max-width: 100px;
          padding-right: 10px;
        }
      }
    }

    &.new-case-fab {
      width: auto;
      min-width: 60px;
      border-radius: 30px;
      &.isHidden {
        transform: translate(-50%, 64px);
      }
      &.isDisabled {
        background-color: $grey5;
      }
    }
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    height: 14px;
    width: 14px;
    align-items: center;
    justify-content: center;
  }

  .mandatory {
    position: absolute;
    top: -5px;
    right: -3px;
    height: 14px;
    font-size: 34px;
    vertical-align: top;
    line-height: 28px;
    color: $primaryPurple;
    z-index: 2;
  }
}
