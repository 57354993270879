@import '~/src/app/utils/variables.module.scss';

.modal-dialog.settings-dialog .MuiPaper-root {
  height: 100%;
  max-height: none;

  .modal-container {
    height: 100%;
    display: flex;
  }
}

.account-settings-container {
  padding: 20px 16px 40px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 10px 0;

    .close-button {
      position: absolute;
      left: 24px;

      .svg-icon {
        height: auto;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .account-settings-form {
    padding: 16px 8px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .email-address {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      text-align: center;
      padding: 20px 0;
    }
    form {
      display: flex;
      flex-direction: column;
      flex: 1;
      .text-field-container {
        position: relative;
        .phone-input {
          background-color: $grey1;
          min-height: 50px;
          border-radius: 12px;
          box-sizing: border-box;
          margin-bottom: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          border: 1px solid $grey3;
          padding: 0 10px;
          &.Mui-focused {
            border-color: $primaryPurple;
          }
        }
        .error-message {
          color: $warning;
          font-size: 12px;
          font-weight: 600;
          position: absolute;
          bottom: 0;
          left: 10px;
        }
      }
    }
    .submit-button {
      margin-top: auto;
      height: 50px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
