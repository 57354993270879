@import '~/src/app/utils/variables.module.scss';

.cases-list-container {
  height: 100%;
  .cases-list {
    padding: 302px 16px 120px;
    &.no-toggles {
      padding-top: 250px;
    }
    &.no-pickers {
      padding-top: 165px;
    }
    &.catch-up {
      padding-top: 165px;
    }
  }

  .case-list-group {
    padding-bottom: 14px;
    .case-list-group-label {
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      height: 40px;
    }
  }
}
