@import '~/src/app/utils/variables.module.scss';

.case-filters {
  .cases-toggle {
    padding: 0 10px;
    margin-top: 20px;
    display: flex;
    .cases-toggle-button {
      margin: 0 5px;
      height: 32px;
      padding: 0 12px;
      overflow: visible;
      background-color: $grey2;
      color: $grey6;
      .badge {
        position: absolute;
        height: 14px;
        width: 14px;
        background-color: $primaryPurple;
        border: 1px solid $white;
        right: 0;
        top: -6px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .svg-icon {
          height: 8px;
          width: 8px;
        }
        &.cases-count-badge {
          height: 18px;
          width: 18px;
          top: -8px;
          right: -2px;
          color: $white;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }
      }
      &.selected {
        background-color: $primaryPurple;
        color: $white;
      }
      &.needsAttention::after {
        content: '';
        position: absolute;
        height: 14px;
        width: 14px;
        background-color: $primaryPurple;
        border-radius: 50%;
        right: 0;
        top: -6px;
        border: 1px solid $white;
        box-sizing: border-box;
      }
    }
  }
  .classic-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .classic-date-picker {
      width: 100%;
      overflow: hidden;
      max-height: 350px;
      padding-bottom: 10px;
      transition: max-height 0.5s ease, padding-bottom 0.5s ease;
    }
    .expand-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      background-color: $white;
      overflow: hidden;
      width: 100%;
      border-bottom: 1px solid $grey2;
      padding-bottom: 6px;
      padding: 0 24px 6px;
      box-sizing: border-box;
      z-index: 3;
      border-radius: 0 0 24px 24px;
    }
    .toggle-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      .date-label {
        font-size: 16px;
        line-height: 40px;
        font-weight: 600;
        white-space: pre;
        flex: 1;
        height: 40px;
      }
    }
    .toggle-button {
      height: 40px;
      width: 40px;
      padding: 0;
      background-color: $grey2;
      z-index: 1;
      .svg-icon {
        height: auto;
      }
    }
    &.minimized {
      .classic-date-picker {
        max-height: 0;
        padding-bottom: 0px;
      }
    }
  }
}
