@import '~/src/app/utils/variables.module.scss';

.other-text-field-container {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  background-color: $pink2;
  min-height: 34px;
  border-radius: 17px;
  margin-bottom: 12px;
  .other-text-field.MuiTextField-root {
    height: 100%;
    width: 100%;
    .MuiInputBase-root {
      min-height: auto;
      border-radius: 17px;
      border: 1px solid $grey3;
      height: 100%;
      &.Mui-focused {
        border-color: $primaryPurple;
      }
      .MuiInputBase-input {
        height: 100%;
        padding: 0 10px 0 10px;
        font-weight: 400;
        font-size: 14px;
      }
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }
  }
  .MuiButton-root.btn.save-button {
    background-color: $white;
    height: 30px;
    width: 30px;
    margin-left: auto;
    position: absolute;
    right: 2px;
  }
  &.single {
    height: 50px;
    border-radius: 12px;
    background-color: $grey2;
    margin: -10px 0 30px 0;
    .other-text-field.MuiTextField-root {
      .MuiInputBase-root {
        border-radius: 12px;
      }
    }
  }
  &.multi {
    width: 100%;
    .other-text-field.MuiTextField-root .MuiInputBase-root {
      padding: 0 10px;
      .MuiInputBase-input {
        padding: 0 36px 0 0;
        &::selection {
          background-color: $pink1;
        }
      }
    }
  }
}
