@import '~/src/app/utils/variables.module.scss';

.week-day-picker {
  max-width: 100vw;
  height: 78px;
  margin: 20px 0 10px;
  position: relative;

  .week {
    display: flex;
    justify-content: space-around;

    .day-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;

      .day-label {
        font-size: 14px;
        color: $grey6;
        margin-bottom: 8px;

        &.selected {
          font-weight: 600;
        }
      }

      .day {
        font-family: 'Inter', sans-serif;
        background-color: $white;
        border-radius: 42px;
        height: 42px;
        width: 42px;
        min-width: auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        font-size: 18px;
        color: $grey6;
        position: relative;
        transition: background-color 100ms ease-in-out, color 100ms ease-in-out;

        &.today {
          background-color: $grey2;
          font-size: 18px;
          font-weight: 600;
        }

        &.selected {
          background-color: $primaryPurple;
          color: $white;
          font-size: 18px;
          font-weight: 600;
        }

        &.hasCases,
        &.hasNeedsAttention {
          &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: calc(50% - 3px);
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: $grey5;
          }
        }
        &.hasNeedsAttention::after {
          background-color: $primaryPurple;
        }
      }
    }
  }

  .swiper {
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 40px;
      z-index: 2;
      pointer-events: none;
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, $white, transparent);
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, $white, transparent);
    }
  }
}
