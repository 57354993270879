@import '~/src/app/utils/variables.module.scss';

.page-container {
  .mail-confirmation-page,
  .add-to-home-screen-page {
    background-color: $white;
    flex: 1;
    padding: 32px 24px 0;
  }
  .mail-confirmation-page {
    .header {
      height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .logo {
        height: 33px;
      }
    }
    .content {
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: $black;
      }
      .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: $grey6;
        margin-top: 16px;
      }
    }
  }
  .add-to-home-screen-page {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .logo-container {
        border: 1px solid $grey3;
        border-radius: 12px;
        height: 80px;
        width: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin-right: 16px;
        .logo {
          height: 48px;
        }
      }
      .header-text {
        line-height: 28px;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: $black;
        }
        .subtitle {
          font-size: 18px;
          font-weight: 400;
          color: $grey6;
        }
      }
    }
    .content {
      .text {
        font-size: 16px;
        font-weight: 400;
        color: $black;
        line-height: 22px;
        &.title {
          margin-bottom: 32px;
        }
        &.subtitle {
          margin-top: 32px;
        }
        .share-icon {
          height: 16px;
          width: auto;
          margin: 0 3px;
        }
      }
      .step-container {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .step-number {
          height: 40px;
          width: 40px;
          border-radius: 20px;
          border: 1px solid $yellow2;
          background-color: $primaryYellow;
          box-sizing: border-box;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          margin-right: 12px;
        }
      }
    }
  }
}
