@import '~/src/app/utils/variables.module.scss';

.new-case-container {
  .form-header {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    .close-button {
      .svg-icon {
        height: auto;
      }
    }
  }
  .selects {
    margin-bottom: 14px;
    padding: 0 24px;
    .selects-row {
      display: flex;
      margin-bottom: 10px;
    }
  }
  .case-title {
    width: 100%;
    margin: 24px 0;
    border: none;
    outline: none;
    padding: 0 24px;
    box-sizing: border-box;
    &,
    .MuiInputBase-root {
      &,
      .MuiInputBase-input {
        &,
        &::placeholder {
          border: none;
          font-size: 16px;
          font-weight: 600;
          font-family: 'Inter', sans-serif;
          color: $grey6;
          opacity: 1;
        }
      }
    }
  }
  .edit-title-button {
    display: none;
  }
  .followers-select {
    border-bottom: none;
  }
  .notifications {
    background-color: $grey2;
    display: flex;
    padding: 12px 24px;
    .svg-icon {
      height: 16px;
      margin-right: 8px;
    }
    .notification-text {
      font-size: 12px;
      line-height: 16px;
      color: $grey6;
    }
  }
}
