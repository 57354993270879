@import '~/src/app/utils/variables.module.scss';

.discard-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px 32px 24px;
  .title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    max-width: 260px;
    white-space: pre-line;
  }
  .text {
    text-align: center;
    margin-top: 12px;
    padding: 0 10px;
  }
  .btn {
    margin-top: 12px;
    border-radius: 10px;
    height: 44px;
    width: 100%;
    &.yes {
      border-color: $grey2;
      color: $warning;
    }
  }
}
